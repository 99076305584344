<template>
  <div></div>
</template>

<script>
import EventBus, { ACTIONS } from "../eventbus/index.js";
export default {
  name: "ApiErrorMessage",
  data() {
    return {
      success: false,
      successMessage: "",
    };
  },
  mounted() {
    EventBus.$on(ACTIONS.SUCCESS, (message) => {
      this.$swal({
        icon: "success",
        title: this.$t("commonLayout.success"),
        text: message,
        showConfirmButton: !1,
        timer: 1e3,
      });
    });

    EventBus.$on(ACTIONS.FAILURE, (message) => {
      this.$swal({
        icon: "error",
        title: this.$t("commonLayout.error"),
        text: message,
        showConfirmButton: !1,
        timer: 1e3,
      });
    });
  },
};
</script>
